// @mui
import { Typography, Stack, Rating, StackProps } from '@mui/material';
// @mui
import { fDate } from 'src/utils/formatTime';
// types
import { ITestimonialProps } from 'src/types/testimonial';
import TextMaxLine from 'src/components/text-max-line';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  // testimonial: ITestimonialProps;
}

export default function TestimonialItemTeacher({ testimonial, sx, ...other }: any) {

  return (
    <Stack
      spacing={1}
      sx={{
        height: 310,
        p: 3,
        borderRadius: 2,
        bgcolor: 'background.neutral',
        overflow: 'hidden',
        ...sx,
      }}
      {...other}
    >
      {testimonial?.createAt && (
        <Typography variant="caption" sx={{ color: 'text.disabled' }}>
          {fDate(testimonial?.createAt)}
        </Typography>
      )}

      {/* <Typography variant="subtitle2">{testimonial?.studentInformation?.studentName}</Typography> */}

      <Rating size="small" value={testimonial?.adminAssessScore} readOnly />
<TextMaxLine line={8}>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {testimonial?.adminAssessContent}
      </Typography>

</TextMaxLine>
    </Stack>
  );
}
