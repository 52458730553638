import { QueryClient } from "@tanstack/react-query";
import ky from "ky";
import { Api } from "./generated";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// export const baseUrl = process.env.REACT_APP_API_URL
// export const baseUrl = "https://demo-vod.learnex.kr/"
// export const baseUrl = "https://attorial.learnex.kr"
export const baseUrl = "https://masadawit.com"
// export const baseUrl = "https://platom.learnex.kr"
// export const baseUrl = "https://test-vod.learnex.kr/"

export const privateApi = new Api({
  baseUrl,
  customFetch: ky.extend({
    timeout: 20000,
    hooks: {
      beforeRequest: [
        async (req: Request) => {
          const accessToken = window.sessionStorage.getItem("x-at");
          if (!accessToken) {
            // message.error("토큰이 존재하지 않거나 잘못된 토큰정보의 요청입니다.");
          }
        },
        (req) => {
          // console.log("nextRequest: ", req);
          const accessToken = window.sessionStorage.getItem("x-at");
          if (accessToken) {
            req.headers.set("Authorization", `Bearer ${accessToken}`);
            // console.log("nextRequest: ", req);
          }
        },
      ],
      afterResponse: [
        async (input, option, response) => {
          console.log("afterResponse: ", response);
          if (!response.ok) {
            if (response.status === 403) {
              // message.error("접근권한이 올바르지 않습니다");
            }
          }
          if (!response.ok) {
            const error = new Error(`[${response.status}] ${response.url}`);
            const body = option.body;
            const headers = option.headers;
          }
        },
      ],
    },
  }),
  baseApiParams: { format: "json" },
});

export const publicApi = new Api({
  baseUrl,
  customFetch: ky.extend({
    throwHttpErrors: false,
    hooks: {
      afterResponse: [
        async (_input, option, response) => {
          if (!response.ok) {
            const error = new Error(`[${response.status}] ${response.url}`);
            const body = option.body;
            const headers = option.headers;
          }
        },
      ],
    },
  }),
  baseApiParams: { format: "json" },
});
