import { Helmet } from 'react-helmet-async';
// sections
import ElearningTestCompletedView from 'src/sections/_e-learning/view/ELearningTestCompletedView';
// ----------------------------------------------------------------------

export default function ElearningQuizCompletePage() {
  return (
    <>
      <Helmet>
        <title>퀴즈 제출 | Masada Wit</title>
      </Helmet>

      <ElearningTestCompletedView />
    </>
  );
}
