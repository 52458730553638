// @mui
import styled from 'styled-components';
// import { Link as RouterLink } from 'react-router-dom';

import { useTheme, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Stack,
  Button,
  AppBar,
  Toolbar,
  Container,
  Typography,
  Grid,
} from '@mui/material';
// hooks

import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { bgBlur, bgGradient } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER } from 'src/config-global';

// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import SettingsDrawer from 'src/components/settings/drawer';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import Carousel, { CarouselDots } from 'src/components/carousel';

//
import { BsPerson, BsCart, BsFillCartFill } from 'react-icons/bs';
import { useEffect, useState, useRef } from 'react';
import TextMaxLine from 'src/components/text-max-line';
import { NavMobile, NavDesktop, navConfig } from '../nav';
import Searchbar from '../../components/Searchbar';
import HeaderShadow from '../../components/HeaderShadow';
import { WpBoardControllerReadWpPostsParams } from '../../../generated';
import { publicApi, privateApi } from '../../../react-query';

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};
// const fetcherMyInfo = () => privateApi.vod.wpStudentControllerWpMyData();
const fetcherAnnounce = (params: WpBoardControllerReadWpPostsParams) =>
  publicApi.vod.wpBoardControllerReadWpPosts(params);

export default function EventPop({ headerOnDark }: Props) {
  /* eslint-disable */

  const theme = useTheme();
  const navigate = useNavigate();

  const isMdUp = useResponsive('up', 'lg');
  const isXsUp = useResponsive('down', 'sm');

  const isOffset = useOffSetTop();
  const [pop, setPop] = useState(true);
  const [NoticeList, setNoticeList] = useState<any[]>([]);

  const carouselRef = useRef<Carousel | null>(null);

  const carouselSettings = {
    // dots: true,
    fade: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,

    rtl: Boolean(theme.direction === 'rtl'),
    // ...CarouselDots({
    //   rounded: true,
    //   sx: {
    //     left: 0,
    //     right: 0,
    //     zIndex: 9,
    //     bottom: 40,
    //     mx: 'auto',
    //     position: 'absolute',
    //   },
    // }),
  };

  useEffect(() => {
    mutateAnnounce({
      searchingDateKey: 'all',

      searchingKey: 'all',
      onMain: '0,1',
      isEvent: '1',
      page: 1,
      pageSize: 30,
      URL:
        sessionStorage.getItem('workingEnv') === 'Build'
          ? window.location.host
          : (sessionStorage.getItem('workingEnv') as string),
    
    });
  }, []);

  const { data: AnnounceData, mutate: mutateAnnounce } = useMutation(
    (params: WpBoardControllerReadWpPostsParams) => fetcherAnnounce(params),
    {
      onSuccess: (e : any) => {
        // console.log(e)
        if (e.data.header.isSuccess) {
          console.log(e.data.body.data);
          setNoticeList(e.data.body.data);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );
  // const {mutate: mutateMyInfo } = useMutation(() => fetcherMyInfo(), {
  //   onSuccess: (e) => {
  //     if (e.data.header.isSuccess) {
  //       console.log(e)

  //     } else {
  //       if(e.data.header.resultCode == 403){

  //         navigate("/auth/duplicated")
  //       }
  //       // message.error(e.data.header.resultMessage);
  //     }
  //   },
  //   onError: (e) => {
  //     console.log("login onError : ", e);
  //   },
  // });

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        top: {xs : '56px' ,md : "72px"},
        bottom: 0,
        height: { xs: 30, md: 50 },
        display: NoticeList.length !== 0 && pop ? 'auto' : 'none',
        background: '#fff0da',
      }}
      // position="fixed"
    >
      <Box
        sx={{
          ...bgGradient({}),
          borderRadius: 0,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Carousel ref={carouselRef} {...carouselSettings}>
          {NoticeList.map((v) => {
            return (
              <Box
                sx={{
                  display: 'flex !important',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: { xs: '30px !important', md: '50px !important' },
                }}
              >
                <div></div>
                <Link
                  component={RouterLink}
                  to={paths.eLearning.posts}
                  onClick={() => {
                    sessionStorage.setItem('postType', '1');
                    // sessionStorage.setItem('post_id',v.noticeIdx)
                    // console.log(v.noticeIdx)
                  }}
                >
                  {/* <span style={{fontWeight:800 ,color:"red"}}>
            [{v.noticeCategory}]

            </span> */}

                  <TextMaxLine
                    sx={{ color: 'black', ml: 5, fontSize: { xs: 10, md: 15 } }}
                    line={1}
                    onClick={() => {}}
                  >
                    <span style={{ fontWeight: 800, color: 'red' }}>[{v?.boardCategoryInformation?.boardCategoryTitle}]</span>
                    <span style={{ fontWeight: 800, color: 'black', marginLeft: 5 }}>
                      {v.postTitle}
                    </span>
                  </TextMaxLine>
                </Link>
                <Button
                  onClick={() => {
                    sessionStorage.setItem('EventPop', 'false');
                    setPop(false);
                  }}
                  // sx={{color :"blue"}}
                >
                  X
                </Button>
              </Box>
            );
          })}
        </Carousel>
      </Box>
      {/* {NoticeList.map((v) => {
          console.log(v)
          return  <div>{v.noticeTitle}</div>
         
        })}
        */}

      {/* </Box> */}

      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}

const HeaderMenu = styled.div`
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    color: #006c9c;
    // background-color: white;
    // opacity: 0.9;
  }
`;
