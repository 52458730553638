
import { useMediaQuery,Theme, IconButton, Box, Typography, Stack } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';

import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from "react-router";
import '../../../assets/css/masada.css';
import { useEffect, useState } from "react";

export default function FooterMobile() {
        /* eslint-disable */

  // const matchmd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const navigate = useNavigate()
  useEffect(()=>{
    console.log(window.location.pathname)
  },[])
  // const [Clicked,setClicked] = useState("")
  return  <Box sx={{position : "fixed", bottom: 0, left:0 ,background:"white", height:56, width: '100%', borderTop: '1px solid #d5d5d5'}}> 
      <Box height={56} display={"flex"} alignItems={"center"} sx={{padding: "7px 8px"}}>
        
      <Box sx={{width: "20%", display: "flex", justifyContent: "center"}}>
          <IconButton 
            onClick={()=>{
              navigate("/e-learning/landing")
              // setClicked("Home")
            }}
            sx={{
              display:"flex", flexDirection:"column",
              // color : Clicked === "Home" ? "#020202" : "#c0c6df"
            }}
          >
            {/* <HomeOutlinedIcon sx={{fontSize: 25}}/> */}
            <i className="icon-home"></i>
            <Typography fontSize={10}>홈</Typography>
          </IconButton>
        </Box>

        <Box sx={{width: "20%", display: "flex", justifyContent: "center"}}>
          <IconButton 
            onClick={()=>{
              navigate("/e-learning/teachers")
              // setClicked("Teacher")
            }}
            sx={{
              display:"flex", flexDirection:"column",
              // color : Clicked === "Teacher" ? "#020202" : "#c0c6df"
            }}
          >
            <i className="icon-tutor"></i>
            {/* <HomeOutlinedIcon sx={{fontSize: 25}}/> */}
            <Typography fontSize={10}>튜터</Typography>
          </IconButton>
        </Box>

        <Box sx={{width: "20%", display: "flex", justifyContent: "center"}}>
          <IconButton 
            onClick={()=>{
              navigate("/e-learning/book")
              // setClicked("Book")
            }}
            sx={{
              display:"flex", flexDirection:"column",
              // color : Clicked === "Book" ? "#020202" : "#c0c6df"
            }}
          >
            {/* <HomeOutlinedIcon sx={{fontSize: 25}}/> */}
            <i className="icon-textbook"></i>
            <Typography fontSize={10}>교재</Typography>
          </IconButton>
        </Box>

        <Box sx={{width: "20%", display: "flex", justifyContent: "center"}}>
          <IconButton 
            // disabled
            onClick={()=>{
              navigate("/e-learning/price")

            }}
            sx={{
              display:"flex", flexDirection:"column",
              // color : Clicked === "CS" ? "#020202" : "#c0c6df",
            }}
          >
            {/* <HomeOutlinedIcon sx={{fontSize: 25}}/> */}
            <i className="icon-price"></i>
            <Typography fontSize={10}>가격</Typography>
          </IconButton>
        </Box>

        <Box sx={{width: "20%", display: "flex", justifyContent: "center"}}>
          <IconButton 
            onClick={()=>{
              // window.history.back();
              navigate("/e-learning/posts")
              // setClicked("CS")
            }}
            sx={{
              display:"flex", flexDirection:"column",
              // color : Clicked === "CS" ? "#020202" : "#c0c6df"
            }}
          >
            {/* <HomeOutlinedIcon sx={{fontSize: 25}}/> */}
            <i className="icon-customer" ></i>
            <Typography fontSize={10}>고객센터</Typography>
          </IconButton>
        </Box>
      
      </Box>
    </Box>
};


