import { useState, useEffect } from 'react';
// @mui
import { useMutation } from '@tanstack/react-query';
import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  Drawer,
  Pagination,
  Grid,
  List,
  ListItem,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
} from '@mui/material';
// config
// _mock
import { _courses } from 'src/_mock';
// components
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LightModeOutlined } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import { SetQuestionsDto, WpQuestionsControllerGetQustionsParams } from 'src/generated';
import useResponsive from 'src/hooks/useResponsive';
import {
  WpQuizControllerReadWpQuizHistoryParams,
  WpQuizControllerUpdateWpQuizHistoryParams,
} from 'src/generated';
import { privateApi } from '../../../react-query';

// ----------------------------------------------------------------------

// const fetcherTestResult = (params: WpQuestionsControllerGetQustionsParams) =>
//   privateApi.vod.wpQuestionsControllerGetQustions(params);

const fetcherChange = (params: WpQuizControllerUpdateWpQuizHistoryParams) =>
  privateApi.vod.wpQuizControllerUpdateWpQuizHistory(params);
const fetcherTestResult = (params: WpQuizControllerReadWpQuizHistoryParams) =>
  privateApi.vod.wpQuizControllerReadWpQuizHistory(params);
export default function ElearningQuizReviewView() {
  /* eslint-disable */
  const [QuestionList, setQuestionList] = useState<any[]>([]);
  const [CurrentQuestion, setCurrentQuestion] = useState<any>({
    quizHistoryIdx: 0,
    questionIdx: 0,
    questionAnswerType: '',
    questionAnswers: [],
    questionAnswerDesc: '',
    questionContent: '',
    questionDifficulty: '',
    questionHint: '',
    questionLevel: '',
    questionTilte: '',
    questionTimer: '',
    retryanswer: [],
    tags: '',
    answer: [],
    duration: 0,
    open: false,
    openhint: false,
  });
  const [QuestionName, setQuestionName] = useState<any>(undefined);
  const [CurrentQuestionNum, setCurrentQuestionNum] = useState<any>(0);
  const [CurrentQuestionIdx, setCurrentQuestionIdx] = useState<any>(undefined);
  const [Retry, setRetry] = useState<any>(0);
  const [First, setFirst] = useState<any>(0);
  const [Summary, setSummary] = useState<any[]>([]);
  const [CanRetry, setCanRetry] = useState<any>(0);
  const [QuizData, setQuizData] = useState<any>();
  const [CorrectCnt, setCorrectCnt] = useState<any>(0);
  const [CorrectPt, setCorrectPt] = useState<any>(0);
  const [TotalPt, setTotalPt] = useState<any>(0);
  const [QuizGroup, setQuizGroup] = useState<any>('');
  const location = useLocation();

  const isMdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  const { mutate: mutateChange } = useMutation(
    (params: WpQuizControllerUpdateWpQuizHistoryParams) => fetcherChange(params),
    {
      onSuccess: (e: any) => {
        console.log(e.data.body.data);
        mutateTestResult({
          quizHistoryGroup: QuizGroup,
        });
        // navigate('/e-learning/quizcomplete');
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );
  const { mutate: mutateTestResult } = useMutation(
    (params: WpQuizControllerReadWpQuizHistoryParams) => fetcherTestResult(params),
    {
      onSuccess: (e: any) => {
        // console.log(e)
        const list: any[] = [];
        if (e.data.header.isSuccess) {
          console.log(e.data.body.data);
          let list: any[] = [];
          let correctCnt: any = 0;
          let totalPt: any = 0;
          let correctPt: any = 0;
          e.data.body.data?.map((v: any) => {
            totalPt = totalPt + v.questionPoint;
            if (v.isCorrect === 1) {
              correctPt = correctPt + 1;
              correctCnt = correctCnt + v.questionPoint;
            }
            list.push({
              quizHistoryIdx: v.quizHistoryIdx,
              questionIdx: v.questionIdx,
              questionAnswerType: v.questionType,
              questionAnswers: JSON.parse(v?.questionOpt) ? JSON.parse(v?.questionOpt) : [],
              questionAnswer: v.questionAns,
              questionDesc: v.questionDesc,
              questionContent: v.questionContent,
              questionPoint: v.questionPoint,
              questionHint: v.questionHint,
              questionLevel: v.questionLevel,
              questionTitle: v.questionTitle,
              questionTimer: '',
              studentanswer: v.inputAns,
              retryanswer: [],
              correct: v.isCorrect,
              tags: '',
              answer: v.inputAns?.split(','),
              duration: v.duration,
              open: false,
              openhint: false,
            });
          });
          setCorrectCnt(correctCnt);
          setTotalPt(totalPt);
          setCorrectPt(correctPt);
          setFirst(First + 1);
          setQuestionList(list);
          // setCurrentQuestion({
          //   questionIdx: Info.data?.question[0]?.questionIdx,
          //   questionAnswerType: Info.data?.question[0]?.questionType,
          //   questionAnswers: JSON.parse(Info.data?.question[0]?.questionOpt)
          //     ? JSON.parse(Info.data?.question[0]?.questionOpt)
          //     : [],
          //   questionAnswerDesc: Info.data?.question[0]?.questionDesc,
          //   questionContent: Info.data?.question[0]?.questionContent,
          //   questionDifficulty: Info.data?.question[0]?.questionPoint,
          //   questionHint: Info.data?.question[0]?.questionHint,
          //   questionLevel: Info.data?.question[0]?.questionLevel,
          //   questionTilte: Info.data?.question[0]?.questionTitle,
          //   questionTimer: '',
          //   tags: '',
          //   answer: [],
          //   duration: 0,
          //   open: false,
          // });
          if (First === 0) {
            setQuestionName(e.data.body.data[0]?.quizTitle);
            console.log(list);
            setCurrentQuestionIdx(e.data.body.data[0]?.questionIdx);
            // setQuestionName(e.data.body.data[0]?.questionsTilte);

            setQuizData(e.data.body.data);
          }
          
          // setQuestionList(Info.data?.question);
          // setCurrentQuestion(Info.data?.question[0]);
          setCanRetry(false);
          // message.error(e.data.header.resultMessage);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );
  // const { mutate: mutateTestResult } = useMutation(
  //   (params: WpQuestionsControllerGetQustionsParams) => fetcherTestResult(params),
  //     {
  //       onSuccess: (e :any) => {
  //         // console.log(e)
  //         const list: any[] = [];
  //         if (e.data.header.isSuccess) {
  //           console.log(e.data.body.data)
  //           let list : any[]= []
  //           setSummary(e.data.body.data.summary)
  //           e.data.body.data.questions.map((v :any)=>{
  //             list.push({
  //               ...v,
  //               questionAnswers : JSON.parse(v?.questionAnswers) ? JSON.parse(v?.questionAnswers) : [],
  //               retryanswer : "",
  //               open : false
  //             })
  //           })
  //           console.log(list)
  //           setQuestionList(list)
  //           setCurrentQuestionIdx(e.data.body.data?.questions[0]?.questionsHistoryIdx)
  //           setQuestionName(e.data.body.data?.questions[0]?.questionsTilte)
  //           setCurrentQuestion({
  //             ...e.data.body.data?.questions[0],
  //             questionAnswers : JSON.parse(e.data.body.data?.questions[0]?.questionAnswers) ? JSON.parse(e.data.body.data?.questions[0]?.questionAnswers) : [],
  //             retryanswer : "",
  //             open : false
  //           })
  //         }
  //       },
  //       onError: (e) => {
  //         console.log('login onError : ', e);
  //       },
  //     }
  //   );

  useEffect(() => {}, [CurrentQuestionIdx]);
  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(new URL(url).search);
    const retry: any = params.get('retry');
    const idx: any = params.get('idx');
    console.log(retry);
    setQuizGroup(idx);
    setRetry(retry == 0 ? 0 : 1);
    const Info = { ...location.state };
    console.log(Info);
    mutateTestResult({
      quizHistoryGroup: idx,
    });

    // mutateTestResult({
    //   questionsHistoryGroup : quizgroup as string
    // })
  }, []);
  useEffect(() => {
    // mutateQuestion(CurrentQuestionIdx)
    if (QuestionList.length !== 0) {
      // console.log(QuestionList);
      // console.log(CurrentQuestionNum);
      console.log(QuestionList);
      setCurrentQuestion({
        quizHistoryIdx: QuestionList[CurrentQuestionNum]?.quizHistoryIdx,
        questionIdx: QuestionList[CurrentQuestionNum]?.questionIdx,
        questionAnswerType: QuestionList[CurrentQuestionNum]?.questionAnswerType,
        questionAnswers: QuestionList[CurrentQuestionNum]?.questionAnswers,
        questionAnswer: QuestionList[CurrentQuestionNum]?.questionAnswer,
        questionDesc: QuestionList[CurrentQuestionNum]?.questionDesc,
        questionContent: QuestionList[CurrentQuestionNum]?.questionContent,
        questionPoint: QuestionList[CurrentQuestionNum]?.questionPoint,
        questionHint: QuestionList[CurrentQuestionNum]?.questionHint,
        questionLevel: QuestionList[CurrentQuestionNum]?.questionLevel,
        questionTilte: QuestionList[CurrentQuestionNum]?.questionTitle,
        questionTimer: '',
        studentanswer: QuestionList[CurrentQuestionNum]?.studentanswer,
        correct: QuestionList[CurrentQuestionNum]?.correct,
        retryanswer: QuestionList[CurrentQuestionNum]?.retryanswer,
        tags: '',
        answer: QuestionList[CurrentQuestionNum]?.answer,
        duration: QuestionList[CurrentQuestionNum]?.duration,
        open: QuestionList[CurrentQuestionNum]?.open,
        openhint: QuestionList[CurrentQuestionNum]?.openhint,
      });
    }
  }, [QuestionList]);
  useEffect(() => {
    // mutateQuestion(CurrentQuestionIdx)
    if (QuestionList.length !== 0) {
      // console.log(QuestionList);
      // console.log(CurrentQuestionNum);
      // console.log(QuestionList[CurrentQuestionNum]?.questionOpt);
      setCurrentQuestion({
        quizHistoryIdx: QuestionList[CurrentQuestionNum]?.quizHistoryIdx,
        questionIdx: QuestionList[CurrentQuestionNum]?.questionIdx,
        questionAnswerType: QuestionList[CurrentQuestionNum]?.questionAnswerType,
        questionAnswers: QuestionList[CurrentQuestionNum]?.questionAnswers,
        questionAnswer: QuestionList[CurrentQuestionNum]?.questionAnswer,
        questionDesc: QuestionList[CurrentQuestionNum]?.questionDesc,
        questionContent: QuestionList[CurrentQuestionNum]?.questionContent,
        questionPoint: QuestionList[CurrentQuestionNum]?.questionPoint,
        questionHint: QuestionList[CurrentQuestionNum]?.questionHint,
        questionLevel: QuestionList[CurrentQuestionNum]?.questionLevel,
        questionTilte: QuestionList[CurrentQuestionNum]?.questionTitle,
        questionTimer: '',
        studentanswer: QuestionList[CurrentQuestionNum]?.studentanswer,
        correct: QuestionList[CurrentQuestionNum]?.correct,
        retryanswer: QuestionList[CurrentQuestionNum]?.retryanswer,
        tags: '',
        answer: QuestionList[CurrentQuestionNum]?.answer,
        duration: QuestionList[CurrentQuestionNum]?.duration,
        open: QuestionList[CurrentQuestionNum]?.open,
        openhint: QuestionList[CurrentQuestionNum]?.openhint,
      });
    }
  }, [QuestionList, CurrentQuestionNum, CurrentQuestionIdx]);

  return (
    <Container sx={{ py: 2 }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box sx={{ bgcolor: '#161C24' }} height={308} px={isMdUp ? 3 : 2} py={5}>
          <Grid container height={'100%'} alignItems={'center'}>
            <Grid item xs={12} md={9}>
              <Box display={'flex'} flexDirection={'column'} gap={2}>
                <Typography fontWeight={700} fontSize={32} sx={{ color: 'white' }}>
                  {QuestionName}
                </Typography>
                <Box display={'flex'} gap={2} flexDirection={isMdUp ? 'row' : 'column'}>
                  <Typography
                    fontSize={isMdUp ? 15 : 12}
                    sx={{ color: '#999', display: 'flex', alignItems: 'center' }}
                  >
                    {' '}
                    <ArticleOutlinedIcon fontSize="small" sx={{ marginRight: '4px' }} />
                    출제유형 :{' '}
                    <Box component="span" sx={{ color: '#eee', paddingLeft: '3px' }}>
                      객관식
                    </Box>{' '}
                  </Typography>
                  <Typography
                    fontSize={isMdUp ? 15 : 12}
                    sx={{ color: '#999', display: 'flex', alignItems: 'center' }}
                  >
                    {' '}
                    <VisibilityOutlinedIcon fontSize="small" sx={{ marginRight: '4px' }} />
                    답안제출 :{' '}
                    <Box component="span" sx={{ color: '#eee', paddingLeft: '4px' }}>
                      {QuestionList.filter((v) => v.answer !== '').length}
                    </Box>{' '}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} mt={isMdUp ? 0 : 1}>
              <Box display={'flex'} flexDirection={'column'} gap={3}>
                {CanRetry == 1 && (
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={() => {
                      setRetry(!Retry);
                    }}
                  >
                    {Retry ? '성적 보기' : '재시험 보기'}
                  </Button>
                )}
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    navigate('/e-learning/mycourse/');
                  }}
                >
                  강의실로 돌아가기
                </Button>
                {/* <Typography sx={{color :"white"}} fontSize={isMdUp ? 16 : 12}>답변 제출기간 : 0000년 00월 00일 까지</Typography> */}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs={12} md={9}>
            <Box display={'flex'} flexDirection={'column'} height={'100%'}>
              <Box height={'90%'} p={2}>
                {/* {CurrentQuestion?.questionTilte !== '' && ( */}
                  <Stack
                    width={'100%'}
                    spacing={1}
                    sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      flexDirection={isMdUp ? 'row' : 'column-reverse'}
                      alignItems={'flex-end'}
                    >
                      <Typography
                        width={'100%'}
                        textAlign={'left'}
                        color={'text.primary'}
                        variant="h5"
                        fontWeight={600}
                        fontSize={isMdUp ? 20 : 16}
                      >
                        문제 : #{CurrentQuestionNum + 1}
                      </Typography>
                      <Box display={'flex'} sx={{ borderRadius: '4px', border: '1px solid #eee' }}>
                        {/* <Box
                          display={'flex'}
                          flexDirection={'column'}
                          width={100}
                          sx={{ bgcolor: '#fbfbfb' }}
                          py={0.3}
                        >
                          <Typography fontWeight={600} fontSize={isMdUp ? 13 : 12} align="center">
                            예상시간
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={0.5}
                            fontSize={isMdUp ? 16 : 12}
                          >
                            <AccessAlarmIcon sx={{ fontSize: 22 }} />
                            {CurrentQuestion?.questionTimer}초
                          </Box>
                        </Box> */}
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          width={100}
                          sx={{ bgcolor: '#fbfbfb' }}
                          py={0.3}
                        >
                          <Typography fontWeight={600} fontSize={isMdUp ? 13 : 12} align="center">
                            유형
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={0.5}
                            fontSize={isMdUp ? 16 : 12}
                          >
                            {/* <AccessAlarmIcon sx={{ fontSize: 22 }} /> */}
                            {CurrentQuestion?.questionAnswerType}
                          </Box>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'} width={100} py={0.3}>
                          <Typography fontWeight={600} fontSize={isMdUp ? 13 : 12} align="center">
                            <Box component="span" sx={{ color: '#FA541C' }}>
                              소요시간
                            </Box>
                          </Typography>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={0.5}
                            fontSize={isMdUp ? 16 : 12}
                            color="#FA541C"
                            fontWeight={600}
                          >
                            <AccessAlarmIcon sx={{ fontSize: 22 }} />
                            {CurrentQuestion?.duration}초
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      width={'100%'}
                      textAlign={'left'}
                      color={'text.primary'}
                      fontWeight={400}
                      fontSize={isMdUp ? 16 : 12}
                    >
                      다음 문제를 읽고 질문에 답하시오.
                    </Typography>
                    <Typography
                      width={'100%'}
                      textAlign={'left'}
                      color={'text.primary'}
                      variant="h5"
                      fontWeight={600}
                      fontSize={isMdUp ? 20 : 16}
                    >
                      퀴즈 제목
                    </Typography>
                    <Typography
                      width={'100%'}
                      textAlign={'left'}
                      color={'text.primary'}
                      fontWeight={400}
                      fontSize={isMdUp ? 16 : 12}
                    >
                      {CurrentQuestion?.questionTilte}
                    </Typography>
                    <Typography
                      width={'100%'}
                      textAlign={'left'}
                      color={'text.primary'}
                      variant="h5"
                      fontWeight={600}
                      fontSize={isMdUp ? 20 : 16}
                    >
                      퀴즈 내용
                    </Typography>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: CurrentQuestion?.questionContent as string,
                      }}
                      style={{ minHeight: 300, marginTop: '-11px' }}
                    ></div>

                    {/* <Box sx={{ border: '1px solid rgba(103, 58, 183, 0.5)', p: 3, borderRadius: 1 }}>
                      <Typography width={'100%'} textAlign={'left'} color={'text.primary'} variant="h5" fontWeight={400} sx={{ mb: 1 }}>
                          제목 : {CurrentQuestion?.questionTilte}
                      </Typography>
                      <Typography width={'100%'} textAlign={'left'} color={'text.primary'} variant="h5" fontWeight={400}>
                          내용 : 
                          <div
                                          dangerouslySetInnerHTML={{ __html: CurrentQuestion?.questionContent as string }}
                                          style={{ maxWidth: 550 }}
                                      ></div>
                      </Typography>
                  </Box> */}
                    <Stack>
                      {Retry === 0 && (
                        <RadioGroup
                          value={CurrentQuestion?.answer}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                        >
                          {CurrentQuestion?.questionAnswers?.map((v: any) => {
                            return (
                              <Stack
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  width: '100%',
                                  border: CurrentQuestion?.studentanswer.includes(v?.no)
                                    ? '2px solid orange !important'
                                    : CurrentQuestion?.questionAnswer?.includes(v?.no)
                                    ? '2px solid red !important'
                                    : '2px solid #f5f5f5',
                                  borderRadius: 1,
                                  py: 1.5,
                                  px: 1,
                                }}
                              >
                                <Radio
                                  disabled
                                  value={v?.no}
                                  checked={
                                    CurrentQuestion?.questionAnswer?.includes(v?.no) ||
                                    CurrentQuestion?.studentanswer.includes(v?.no)
                                  }
                                  sx={{
                                    color: CurrentQuestion?.studentanswer.includes(v?.no)
                                      ? 'orange !important'
                                      : CurrentQuestion?.questionAnswer?.includes(v?.no)
                                      ? 'red !important'
                                      : 'lightgray',
                                  }}
                                />
                                <Typography
                                  sx={{
                                    color: CurrentQuestion?.questionAnswer?.includes(v?.no)
                                      ? 'red'
                                      : 'black',
                                  }}
                                >
                                  {v?.content}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </RadioGroup>
                      )}
                      {Retry === 1 && (
                        <RadioGroup
                          value={CurrentQuestion?.answer}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                        >
                          {CurrentQuestion?.questionAnswers?.map((v: any) => {
                            return (
                              <Stack
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  width: '100%',
                                  border: CurrentQuestion?.studentanswer.includes(v?.no)
                                    ? '2px solid orange !important'
                                    : '2px solid #f5f5f5',
                                  borderRadius: 1,
                                  py: 1.5,
                                  px: 1,
                                }}
                              >
                                <Radio
                                  // disabled
                                  value={v?.no}
                                  checked={CurrentQuestion?.retryanswer?.includes(v?.no)}
                                  onClick={(e) => {
                                    // console.log(e.target.checked)

                                    let list: any[] = [...QuestionList];
                                    let answerlist: any[] = [...CurrentQuestion?.retryanswer];
                                    console.log(answerlist);
                                    if (answerlist.includes(v?.no)) {
                                      const findIndex = list.findIndex(
                                        (element: any) =>
                                          element.questionIdx === CurrentQuestion?.questionIdx
                                      );
                                      answerlist = answerlist.filter((c) => c !== v?.no);
                                      list[findIndex] = {
                                        ...list[findIndex],
                                        retryanswer: answerlist,
                                      };
                                      console.log(list);
                                      setQuestionList(list);
                                      let current: any = CurrentQuestion;
                                      current = {
                                        ...CurrentQuestion,
                                        retryanswer: answerlist,
                                      };
                                      // console.log(current)
                                      setCurrentQuestion(current);
                                    } else {
                                      answerlist.push(v?.no);
                                      const findIndex = list.findIndex(
                                        (element: any) =>
                                          element.questionIdx === CurrentQuestion?.questionIdx
                                      );
                                      list[findIndex] = {
                                        ...list[findIndex],
                                        retryanswer: answerlist,
                                      };
                                      console.log(list);
                                      setQuestionList(list);
                                      let current: any = CurrentQuestion;
                                      current = {
                                        ...CurrentQuestion,
                                        retryanswer: answerlist,
                                      };
                                      // console.log(current)
                                      setCurrentQuestion(current);
                                    }
                                  }}
                                />
                                <Typography>{v?.content}</Typography>
                              </Stack>
                            );
                          })}
                        </RadioGroup>
                      )}

                     {Retry ===1 && <Button
                        variant="contained"
                        disabled={CurrentQuestion?.answer === ''}
                        sx={{ width: '100%', mt: 2 }}
                        onClick={() => {
                          if (Retry === 1) {
                            mutateChange({
                              quizHistoryIdx: CurrentQuestion?.quizHistoryIdx,
                              inputAns: CurrentQuestion?.retryanswer.join(','),
                            });
                          }
                        }}
                      >
                        답변 수정하기
                      </Button>}
                      <Button
                        variant="outlined"
                        disabled={CurrentQuestion?.answer === ''}
                        sx={{ width: '100%', mt: 2 }}
                        onClick={() => {
                          console.log(CurrentQuestion);
                          let list: any[] = [...QuestionList];
                          const findIndex = list.findIndex(
                            (element: any) => element.questionIdx === CurrentQuestion?.questionIdx
                          );
                          list[findIndex] = {
                            ...list[findIndex],
                            openhint: !CurrentQuestion?.openhint,
                          };
                          setQuestionList(list);
                          let current: any = CurrentQuestion;
                          current = {
                            ...CurrentQuestion,
                            openhint: !CurrentQuestion?.openhint,
                          };
                          console.log(current);
                          setCurrentQuestion(current);
                        }}
                      >
                        {CurrentQuestion?.openhint ? '힌트 접기' : '힌트 보기'}
                      </Button>

                      {CurrentQuestion.openhint && (
                        <>
                          <Typography
                            width={'100%'}
                            textAlign={'left'}
                            color={'text.primary'}
                            variant="h5"
                            fontWeight={600}
                            fontSize={isMdUp ? 20 : 16}
                            sx={{ mt: 3 }}
                          >
                            퀴즈 힌트
                          </Typography>
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 1,
                              mt: 2,
                              bgcolor: '#fcfcf9',
                            }}
                          >
                            <Typography
                              width={'100%'}
                              textAlign={'left'}
                              color={'text.primary'}
                              variant="h5"
                              fontWeight={400}
                              sx={{ marginTop: '-8px', px: 2 }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CurrentQuestion?.questionHint as string,
                                }}
                                style={{ minHeight: 300 }}
                              ></div>
                            </Typography>
                          </Stack>
                        </>
                      )}

                      <Button
                        variant="outlined"
                        disabled={CurrentQuestion?.answer === ''}
                        sx={{ width: '100%', mt: 2 }}
                        onClick={() => {
                          console.log(CurrentQuestion);
                          let list: any[] = [...QuestionList];
                          const findIndex = list.findIndex(
                            (element: any) => element.questionIdx === CurrentQuestion?.questionIdx
                          );
                          list[findIndex] = {
                            ...list[findIndex],
                            open: !CurrentQuestion?.open,
                          };
                          setQuestionList(list);
                          let current: any = CurrentQuestion;
                          current = {
                            ...CurrentQuestion,
                            open: !CurrentQuestion?.open,
                          };
                          console.log(current);
                          setCurrentQuestion(current);
                        }}
                      >
                        {CurrentQuestion?.open ? '문제풀이 접기' : '문제풀이 보기'}
                      </Button>

                      {CurrentQuestion.open && (
                        <>
                          {/* <Typography fontWeight={600} fontSize={isMdUp ? 16 : 12}>
                            시험 입력 답: {CurrentQuestion.studentAnswered}번
                          </Typography> */}
                          <Typography fontWeight={600} fontSize={isMdUp ? 16 : 12}>
                            정답 : {CurrentQuestion.questionAnswer}
                          </Typography>
                          <Typography fontWeight={200} fontSize={isMdUp ? 16 : 12}>
                            문제 점수 : {CurrentQuestion.questionPoint} 점
                          </Typography>
                          <Typography fontWeight={200} fontSize={isMdUp ? 16 : 12}>
                            문제 수준 :{' '}
                            {CurrentQuestion.questionLevel === 1
                              ? '초급'
                              : CurrentQuestion.questionLevel === 2
                              ? '중급'
                              : '고급'}
                          </Typography>

                          <Typography
                            width={'100%'}
                            textAlign={'left'}
                            color={'text.primary'}
                            variant="h5"
                            fontWeight={600}
                            fontSize={isMdUp ? 20 : 16}
                            sx={{ mt: 3 }}
                          >
                            퀴즈 해설
                          </Typography>
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 1,
                              mt: 2,
                              bgcolor: '#fcfcf9',
                            }}
                          >
                            <Typography
                              width={'100%'}
                              textAlign={'left'}
                              color={'text.primary'}
                              variant="h5"
                              fontWeight={400}
                              sx={{ marginTop: '-8px', px: 2 }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: CurrentQuestion?.questionDesc as string,
                                }}
                                style={{ minHeight: 300 }}
                              ></div>
                            </Typography>
                          </Stack>
                        </>
                      )}
                    </Stack>

                    <Box
                      display={'flex'}
                      width={'100%'}
                      justifyContent={'center'}
                      gap={3}
                      alignItems={'center'}
                      pt={3}
                      pb={2}
                    >
                      <IconButton
                        disabled={CurrentQuestionNum === 0}
                        sx={{
                          border:
                            CurrentQuestionNum === 0 ? '2px solid lightgray' : '2px solid #673ab7',
                          color: '#212B36',
                          borderRadius: 1,
                          padding: { xs: 1, md: '10px 16px' },
                        }}
                        onClick={() => {
                          const findIndex = QuestionList.findIndex(
                            (element: any) => element.questionIdx === CurrentQuestionIdx
                          );
                          setCurrentQuestionNum(findIndex - 1);
                          if (findIndex > 0) {
                            setCurrentQuestionIdx(QuestionList[findIndex - 1].questionIdx);
                            setCurrentQuestion(QuestionList[findIndex - 1]);
                          } else {
                          }
                        }}
                      >
                        <ArrowBackIosNewIcon
                          sx={{ fontSize: isMdUp ? 18 : 12, marginRight: '6px' }}
                        />
                        <Typography fontSize={isMdUp ? 16 : 12}>이전 문제</Typography>
                      </IconButton>

                      <Typography>
                        {CurrentQuestionNum + 1} / {QuestionList.length}
                      </Typography>

                      <IconButton
                        sx={{
                          border:
                            CurrentQuestionNum === QuestionList.length - 1
                              ? '2px solid lightgray'
                              : '2px solid #9574d0',
                          color: '#212B36',
                          borderRadius: 1,
                          padding: { xs: 1, md: '10px 16px' },
                        }}
                        disabled={CurrentQuestionNum === QuestionList.length - 1}
                        onClick={() => {
                          // if (Retry === 1) {
                          //   mutateChange({
                          //     quizHistoryIdx: CurrentQuestion?.quizHistoryIdx,
                          //     inputAns: CurrentQuestion?.retryanswer.join(','),
                          //   });
                          // }
                          const findIndex = QuestionList.findIndex(
                            (element: any) => element.questionIdx === CurrentQuestionIdx
                          );
                          setCurrentQuestionNum(findIndex + 1);
                          if (findIndex < QuestionList.length - 1) {
                            setCurrentQuestionIdx(QuestionList[findIndex + 1].questionIdx);
                            setCurrentQuestion(QuestionList[findIndex + 1]);
                          } else {
                          }
                        }}
                      >
                        <Typography fontSize={isMdUp ? 16 : 12}>다음 문제</Typography>
                        <ArrowForwardIosIcon
                          sx={{ fontSize: isMdUp ? 18 : 12, marginLeft: '6px' }}
                        />
                      </IconButton>
                    </Box>

                    {/* <Divider />
                  <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                      <SchoolOutlined color="primary" />
                      <Typography width={'100%'} textAlign={'left'} color={'text.primary'} variant="h5" fontWeight={400}>
                      <div
                                          dangerouslySetInnerHTML={{ __html: CurrentQuestion?.questionAnswerDesc as string }}
                                          style={{ maxWidth: 550 }}
                                      ></div>
                      </Typography>
                  </Stack> */}
                  </Stack>
                {/* )} */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} style={{ height: '100%', position: 'sticky', top: '50px' }}>
            <Box
              sx={{
                padding: 5,
                borderRadius: 2,
                height: '100%',
                minHeight: isMdUp ? 757 : '100%',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
              }}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={1}>
                <Typography fontWeight={600} fontSize={'.875rem'}>
                  점수
                </Typography>
                <Box
                  display={'flex'}
                  gap={1}
                  sx={{ width: '100%', border: '1px solid #ccc', borderRadius: '2px' }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={'18px'}
                    p={0.5}
                    sx={{ flex: '1', bgcolor: '#f7f7f7' }}
                  >
                    총 문제 수 : {QuestionList?.length}개
                  </Typography>
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={'18px'}
                    p={0.5}
                    sx={{ color: '#FA541C', flex: '1' }}
                  >
                    맞힌 문제 수 : {CorrectCnt}개
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  gap={1}
                  sx={{ width: '100%', border: '1px solid #ccc', borderRadius: '2px' }}
                >
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={'18px'}
                    p={0.5}
                    sx={{ flex: '1', bgcolor: '#f7f7f7' }}
                  >
                    총 점수 : {TotalPt}점
                  </Typography>
                  <Typography
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={'18px'}
                    p={0.5}
                    sx={{ color: '#FA541C', flex: '1' }}
                  >
                    맞힌 점수 : {CorrectPt}점
                  </Typography>
                </Box>

                <Divider sx={{ width: '100%', margin: '16px 0', borderStyle: 'dashed' }} />

                <Typography
                  fontWeight={600}
                  fontSize={14}
                  lineHeight={'18px'}
                  sx={{ color: '#212B36' }}
                  pb={1.6}
                >
                  정답지
                </Typography>
                <Grid container gap={1}>
                  {QuestionList.map((v, i) => {
                    return (
                      <Grid item xs={2}>
                        <IconButton
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            background: QuestionList[i]?.correct !== 0 ? 'green' : 'red',
                            color: QuestionList[i]?.answer !== '' ? 'white' : '#673ab7',
                            borderRadius: 0.8,
                            '&:hover': {
                              color: '#212B36',
                            },
                          }}
                          onClick={() => {
                            const findIndex = QuestionList.findIndex(
                              (element: any) => element.questionIdx === v.questionIdx
                            );
                            setCurrentQuestion(QuestionList[findIndex]);
                          }}
                        >
                          <Typography fontSize={16}>
                            {/* {i+1} */}
                            {i + 1}
                          </Typography>
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>

              <Box></Box>

              <Box display={'flex'} flexDirection={'column'} gap={1} mt={1}>
                <Divider sx={{ marginBottom: '12px', marginTop: '14px', borderStyle: 'dashed' }} />

                <Box
                  display={'flex'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <IconButton
                    disabled={CurrentQuestionNum === 0}
                    sx={{
                      border:
                        CurrentQuestionNum === 0 ? '2px solid lightgray' : '2px solid #673ab7',
                      color: '#673ab7',
                      borderRadius: 0.8,
                    }}
                    onClick={() => {
                      const findIndex = QuestionList.findIndex(
                        (element: any) => element.questionIdx === CurrentQuestionIdx
                      );
                      setCurrentQuestionNum(findIndex - 1);
                      if (findIndex > 0) {
                        setCurrentQuestionIdx(QuestionList[findIndex - 1].questionIdx);
                        setCurrentQuestion(QuestionList[findIndex - 1]);
                      } else {
                      }
                    }}
                  >
                    <ArrowBackIosNewIcon></ArrowBackIosNewIcon>
                  </IconButton>

                  <Typography>
                    {CurrentQuestionNum + 1} / {QuestionList.length}
                  </Typography>

                  <IconButton
                    sx={{
                      border:
                        CurrentQuestionNum === QuestionList.length - 1
                          ? '2px solid lightgray'
                          : '2px solid #673ab7',
                      color: '#673ab7',
                      borderRadius: 2,
                    }}
                    disabled={CurrentQuestionNum === QuestionList.length - 1}
                    onClick={() => {
                      const findIndex = QuestionList.findIndex(
                        (element: any) => element.questionIdx === CurrentQuestionIdx
                      );
                      setCurrentQuestionNum(findIndex + 1);
                      if (findIndex < QuestionList.length - 1) {
                        setCurrentQuestionIdx(QuestionList[findIndex + 1].questionIdx);
                        setCurrentQuestion(QuestionList[findIndex + 1]);
                      } else {
                      }
                    }}
                  >
                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                  </IconButton>
                </Box>

                {CanRetry == 1 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ marginTop: '10px', padding: '10px 8px' }}
                    onClick={() => {
                      setRetry(!Retry);
                    }}
                  >
                    {Retry ? '성적 보기' : '재시험 보기'}
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: '6px', padding: '10px 8px' }}
                  onClick={() => {
                    navigate('/e-learning/mycourse/');
                  }}
                >
                  강의실로 돌아가기
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

// ----------------------------------------------------------------------
