import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
// import { Digit } from 'react-digit-components';
// @mui
import { Box, Typography, Stack, Tooltip ,useMediaQuery,Theme} from '@mui/material';
// config
import { SiKakaotalk,SiNaver } from "react-icons/si";
import { FaTwitter,FaInstagramSquare,FaFacebookSquare,FaLine } from "react-icons/fa";
import { TiSocialGithub } from 'react-icons/ti';
import { HEADER } from 'src/config-global';
//
// import Fab from /'@mui/material/Fab';
import Header from './header/Header';
import Footer from './footer/Footer';
import EventPop from './eventPop/EventPop';
import BannerTimer from './banner/BannerTimer';
import BannerImage from './banner/BannerImage';
import BannerText from './banner/BannerText';
import FooterMobile from './footer/FooterMobile';
// import AddIcon from '@mui/icons-material/Add';


// ----------------------------------------------------------------------

const pathsOnDark = ['/career/landing', '/travel/landing'];

const spacingLayout = [...pathsOnDark, '/e-learning/landing', '/e-learning/courses'];

// ----------------------------------------------------------------------

export default function MainLayout() {
  /* eslint-disable */
  const matchmd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const actionPage = (arr: string[]) => arr.some((path) => pathname === path);
  const [List, setList] = useState<any>([])
  useEffect(() => {
    let list : any[] = []
    // window.location.pathname === "/vod" ?
    // navigate("/e-learning/landing")
    // :""
    // window.location.pathname === "/vod/" ?
    // navigate("/e-learning/landing")
    // :""

    // navigate("/e-learning/landing")

    if (sessionStorage.getItem('EventPop') !== 'false') {
      sessionStorage.setItem('EventPop', 'true');
    }
    console.log(window.location.pathname);
    sessionStorage.setItem(
      'Dns',
      sessionStorage.getItem('workingEnv') === 'Build'
        ? window.location.host
        : (sessionStorage.getItem('workingEnv') as string)
    );

    if(sessionStorage.getItem('kakaoVisible') === "1"){
      list.push( { icon: <SiKakaotalk />, name: '카카오톡', link: sessionStorage.getItem('kakaoUrl') })
    }
    if(sessionStorage.getItem('facebookVisible') === "1"){
      list.push( { icon: <FaFacebookSquare />, name: '페이스북', link: sessionStorage.getItem('facebookUrl') },)

    }

    if(sessionStorage.getItem('lineVisible') === "1"){
      list.push( { icon: <FaLine />, name: '라인', link: sessionStorage.getItem('lineUrl') })
      
    }
    if(sessionStorage.getItem('instarVisible') === "1"){
      list.push( { icon: <FaInstagramSquare />, name: '인스타그램', link: sessionStorage.getItem('instarUrl') })

    }
    if(sessionStorage.getItem('bandVisible') === "1"){
      list.push( { icon: <SiNaver />, name: '밴드', link: sessionStorage.getItem('bandUrl') })

    }
    if(sessionStorage.getItem('xVisible') === "1"){
      list.push( { icon: <FaTwitter />, name: '트위터', link: sessionStorage.getItem('xUrl')})

    }
    console.log(list)
    setList(list)

  }, []);

  const handleButtonClick = (link: string) => {
    window.open(link, '_blank');
  };
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      {/* <Fab color="primary" aria-label="add" sx={{position:"absolute" , right : 10 ,bottom :3}}>1</Fab> */}
      <Header headerOnDark={actionPage(pathsOnDark)} />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        {/* <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}> */}
        {List.length !==  0 &&<Tooltip title='SNS 바로가기'>
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: '10%', right: 30 }}
            icon={<TiSocialGithub size={'40px'} />}
            // FabProps={{
            //   style: { backgroundColor: '#fff', color: '#000' }
            // }}
          >
            {List.map((action : any) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={() => handleButtonClick(action.link)}
              />
            ))}
          </SpeedDial>

        </Tooltip>}
        {sessionStorage.getItem("banner1Visible") === "1" &&
        sessionStorage.getItem("banner1Type") === "COUNTER" &&
        sessionStorage.getItem("bannerShow") !== "false"
        && <BannerTimer label={sessionStorage.getItem("banner1Contents")} time={sessionStorage.getItem("banner1Counter")} 
        link={sessionStorage.getItem("banner1Url")}
        />}
        {sessionStorage.getItem("banner1Visible") === "1" &&
        sessionStorage.getItem("banner1Type") === "IMAGE" &&
        sessionStorage.getItem("bannerShow") !== "false"
        && <BannerImage img={sessionStorage.getItem("banner1Contents")} link={sessionStorage.getItem("banner1Url")} />}
        {sessionStorage.getItem("banner1Visible") === "1" &&
        sessionStorage.getItem("banner1Type") === "TEXT" &&
        sessionStorage.getItem("bannerShow") !== "false"
        && <BannerText label={sessionStorage.getItem("banner1Contents")} link={sessionStorage.getItem("banner1Url")}/>}
        {/* <BannerText />
        <BannerImage /> */}
        {/* </Box> */}
        {!actionPage(spacingLayout) && <Spacing />}
        <Outlet />
      </Box>
      {window.location.pathname === '/e-learning/mycourseplay' ? '' : <Footer />}
       {matchmd && <FooterMobile />}
      {sessionStorage.getItem('EventPop') === 'true' && (
        <EventPop headerOnDark={actionPage(pathsOnDark)} />
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Spacing() {
  return (
    <Box
      sx={{
        height: { xs: HEADER.H_MOBILE, md: HEADER.H_MAIN_DESKTOP-16 },
      }}
    />
  );
}
