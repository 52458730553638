import { useState, useEffect } from 'react';
// @mui
import { useMutation } from '@tanstack/react-query';

import {
  Container,
  Stack,
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  Drawer,
  Pagination,
} from '@mui/material';
// config
import { NAV } from 'src/config-global';
// _mock
import { _courses } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
//
import { useNavigate, useParams } from 'react-router-dom';

import { SelectChangeEvent } from '@mui/material/Select';
import scrollToTop from 'src/components/scroll-to-top';

import useResponsive from 'src/hooks/useResponsive';
import { createBrowserHistory } from 'history';
import { ICourseFiltersProps } from 'src/types/course';
import { WpVodControllerReadWpVodProductsParams, WpVodControllerReadAdminsParams } from 'src/generated';
import Image from 'src/components/image';
import { BiSolidGridAlt, BiListUl } from 'react-icons/bi';

import { publicApi } from '../../../react-query';

import {
  ElearningFilterFee,
  ElearningFilterLevel,
  ElearningFilterRating,
  ElearningFilterLanguage,
  ElearningFilterDuration,
  ElearningFilterCategories,
} from '../course/filters/components';
import ElearningTeacherList from '../teacher/list/ElearningTeacherList';
// ----------------------------------------------------------------------
const fetcherCourses = (params: WpVodControllerReadWpVodProductsParams) =>
  publicApi.vod.wpVodControllerReadWpVodProducts(params);
const fetcherTeachers = (params: WpVodControllerReadAdminsParams) =>
  publicApi.vod.wpVodControllerReadAdmins(params);

const defaultValues = {
  filterDuration: [],
  filterCategories: [],
  filterRating: 'all',
  filterFee: [],
  filterLevel: ['all'],
  filterLanguage: [],
};
export default function ElearningTeachersView() {
  /* eslint-disable */
  const history = createBrowserHistory();
  const params = useParams();
  const isMdUp = useResponsive('up', 'md');
  const isMdDown = useResponsive('down', 'md');
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [mobileOpen, setMobileOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [TeacherList, setTeacherList] = useState<any[]>([]);
  const [SearchVal, setSerachVal] = useState('');
  const [SelectRating, setSelectRating] = useState('all');
  const [SelectCategory, setSelectCategory] = useState<string[]>(['all']);
  const [SelectLevel, setSelectLevel] = useState<string>('all');
  const [SelectedType, setSelectedType] = useState<string>('1');
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(1);
  const navigate = useNavigate();
  const [filters, setFilters] = useState<ICourseFiltersProps>(defaultValues);

  useEffect(() => {
    const listenBackEvent = () => {
      // 뒤로가기 할 때 수행할 동작을 적는다
      console.log('back');
      setPage(Number(params.page));
      console.log(params);
      console.log(window.location.pathname);
      window.scrollTo({ top: 0, left: 0 });
    };
    const unlistenHistoryEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });
    return unlistenHistoryEvent;
  }, []);

  useEffect(() => {
    if (isMdDown) {
      setSelectedType('2');
    } else {
      setSelectedType('1');
    }
  }, [isMdDown]);

  useEffect(() => {
    mutateTeachers({
      URL:
        sessionStorage.getItem('workingEnv') === 'Build'
          ? window.location.host
          : (sessionStorage.getItem('workingEnv') as string),
      page: Page,
      pageSize: 9,
    });
  }, [Page]);
  useEffect(() => {
    filters.filterCategories = [];
    mutateTeachers({
      URL:
        sessionStorage.getItem('workingEnv') === 'Build'
          ? window.location.host
          : (sessionStorage.getItem('workingEnv') as string),
      page: 1,
      pageSize: 9,
    });
    const fakeLoading = async () => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false);
    };
    fakeLoading();
  }, []);

  const handleMobileOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileClose = () => {
    setMobileOpen(false);
  };

  const { data: Teachers, mutate: mutateTeachers } = useMutation(
    (params: WpVodControllerReadAdminsParams) => fetcherTeachers(params),
    {
      onSuccess: (e: any) => {
        // console.log(e)
        if (e.data.header.isSuccess) {
          console.log(e);
          setPageSize(e.data.body.total);
          setTeacherList(e.data.body.data);
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setPage(value);
    navigate(`/e-learning/courses/${value}`);
  };

  return (
    <>
      <Container sx={{ mt: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            py: 5,
          }}
        >
          <Typography variant="h2">강사 목록</Typography>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="carbon:filter" width={18} />}
            onClick={handleMobileOpen}
            sx={{
              display: { md: 'none' },
            }}
          >
            필터
          </Button>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }}>
          {/* <ElearningFilters mobileOpen={mobileOpen} onMobileClose={handleMobileClose} /> */}
          {/* <>
            {isMdUp ? (
              renderContent
            ) : (
              <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleMobileClose}
                ModalProps={{ keepMounted: true }}
                PaperProps={{
                  sx: {
                    pt: 5,
                    px: 3,
                    width: NAV.W_DRAWER,
                  },
                }}
              >
                {renderContent}
              </Drawer>
            )}
          </> */}

          <Box
            sx={{
              flexGrow: 1,
              pl: { md: 8 },
              width: { md: `calc(100% - ${NAV.W_DRAWER}px)` },
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <div>
                <Typography variant="h6">총 강사 : {TeacherList.length}명</Typography>
              </div>
              <Box
                sx={{
                  border: '1px solid #FA541C',
                  borderRadius: 15,
                  padding: 1,
                  display: { md: 'inline', sm: 'none', xs: 'none' },
                }}
              >
                {/* <Stack direction={"row"} sx={{display: {md: "inline", xs:"none"}}}> */}
                <Button
                  sx={{
                    minWidth: '36px',
                    margin: '0 4px',
                    background: SelectedType === '1' ? 'rgba(250, 84, 28, 0.16)' : 'none',
                  }}
                  onClick={() => {
                    setSelectedType('1');
                    // navigate(`/e-learning/courses?page=${Page}&type=1`)
                  }}
                >
                  <BiListUl fontSize={18} />
                </Button>

                <Button
                  sx={{
                    minWidth: '36px',
                    margin: '0 4px',
                    background: SelectedType === '2' ? 'rgba(250, 84, 28, 0.16)' : 'none',
                  }}
                  onClick={() => {
                    setSelectedType('2');
                    // navigate(`/e-learning/courses?page=${Page}&type=2`)
                  }}
                >
                  <BiSolidGridAlt fontSize={18} />
                </Button>
              </Box>
              {/* <span>{PageSize}</span> */}
            </div>

            {PageSize === 0 ? (
              <Box display="flex" flexDirection={'column'}>
                <Image
                  alt="comingsoon"
                  src="/assets/illustrations/illustration_comingsoon.svg"
                  sx={{
                    my: 3,
                    mx: 'auto',
                    maxWidth: 320,
                  }}
                />
                <span style={{ fontSize: 16, fontWeight: 400, margin: 'auto', color: '#9599a7' }}>
                  검색 조건에 맞는 상품이 없습니다.
                </span>
              </Box>
            ) : (
              <>
                <ElearningTeacherList
                  teachers={TeacherList}
                  loading={loading}
                  type={SelectedType}
                />
                <Pagination
                  count={Math.floor(PageSize / 9) + 1}
                  color="primary"
                  size="large"
                  onChange={handleChange}
                  page={Page}
                  sx={{
                    my: 10,
                    '& .MuiPagination-ul': {
                      justifyContent: 'center',
                    },
                  }}
                />
              </>
            )}
          </Box>
        </Stack>
      </Container>
      {/* <NewsletterElearning />  */}
    </>
  );
}

// ----------------------------------------------------------------------

type BlockProps = {
  title: string;
  children: React.ReactNode;
};

function Block({ title, children }: BlockProps) {
  return (
    <Stack spacing={1.5}>
      <Typography variant="overline" sx={{ color: 'text.disabled' }}>
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
