import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
// types
import { ICourseProps } from 'src/types/course';
// components
import { useMutation } from '@tanstack/react-query';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsCart, BsCartFill } from 'react-icons/bs';
import { privateApi, publicApi } from 'src/react-query';
import { WpVodControllerReadWpVodProductsParams } from 'src/generated';
import Timer from 'src/components/timer/Timer';
import SelectProductModal from './SelectProductModal';

// ----------------------------------------------------------------------

type Props = {
  teacher: any;
  vertical?: boolean;
  length?: number;
  simClicked?: any;
};

const fetcherCourses = (params: WpVodControllerReadWpVodProductsParams) =>
  publicApi.vod.wpVodControllerReadWpVodProducts(params);

export default function ElearningCourseItem({ teacher, vertical, length, simClicked }: Props) {
  /* eslint-disable */
  const [Open, setOpen] = React.useState<any>(false);
  const [Message, setMessage] = React.useState('');
  const [open2, setOpen2] = React.useState(false);
  const [Check, setCheck] = React.useState<any>();
  const [CourseList, setCourseList] = React.useState<any>([]);

  const navigate = useNavigate();
  const { data: LengthList, mutate: mutateCourses } = useMutation(
    (params: WpVodControllerReadWpVodProductsParams) => fetcherCourses(params),
    {
      onSuccess: (e: any) => {
        // console.log(e)
        if (e.data.header.isSuccess) {
          console.log(e);
          // setPageSize(e.data.body.total)
          const list: any[] = [];
          e.data.body.data.filter((c:any)=>c.isLiveAdminFixed === 0).map((v: any) => {
            list.push({
              id: v.vodProductIdx,
              createdAt: new Date(),
              slug: v.vodProductTitle,
              coverImg: v.vodProductPhotoFilePath,
              category: v.vodProductCategoryInformation?.vodProductCategoryTitle,
              description: v.vodProductInfo,
              price: v.vodProductPrice,
              usetimer: v.isVodProductTimer,
              time: v.vodProductTimer,
              level:
                v.vodProductLevel === 1
                  ? 'Beginner'
                  : v.vodProductLevel === 2
                  ? 'Intermediate'
                  : 'Expert',
              priceSale: v?.vodProductDiscountPrice,
              ratings: v?.totalScoreAvg,
              period: v?.vodProductPeriod,
              quizzes: 1,
              students: Number(v.totalMemberCnt),              // resources : 100,
              totalHours: v?.totalDuration,
              // + Math.ceil(Number(v.vodDuration.slice(3,5))/60)
              teachers: [
                {
                  id: 1,
                  name: v.teacherName,
                  // role : "teacher",
                  picture: v.teacherPhotoFilePath,
                  // courses : 46,
                  // reviews : 100,
                  // student : 40,
                  // rating : 3.5
                },
              ],
              isLive: {
                isLive: v.isLive,
                liveCount: v.liveCount,
                liveLimit: v.liveLimit,
                liveWeek: v.liveWeek,
                liveDuration: v.liveDuration,
              },
            
            });
          });
          console.log(list);
          setCourseList(list);
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
          // message.error(e.data.header.resultMessage);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  return (
    <Card
      sx={{
        display: { sm: 'flex' },
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        ...(vertical && {
          flexDirection: 'column',
        }),
        margin: 'auto',
        width: length === 1 ? { sm: '33%' } : length === 2 ? { sm: '70%' } : { sm: '100%' },
      }}
    >
      <Box
        sx={{ flexShrink: { sm: 0 } }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Link
          component={RouterLink}
          to={`${paths.eLearning.teacher}?id=${teacher?.adminIdx}`}
          color="inherit"
          height={1}
        >
          <Image
            alt={teacher?.adminName}
            src={
              teacher?.adminPhotoFilePath === null || teacher?.adminPhotoFilePath === ''
                ? '/assets/images/portrait/portrait_1.jpg'
                : teacher?.adminPhotoFilePath
            }
            sx={{
              borderRadius: '2 0 ',
              cursor: 'pointer',
              height: '100%',
              objectFit: 'cover',
              width: { sm: 240, md: 220 },
              ...(vertical && {
                width: { sm: 1 },
              }),
            }}
            onClick={() => {}}
          />
        </Link>
      </Box>

      <Stack
        spacing={1}
        sx={{ p: 3 }}
        // pb: {md: vertical ? 3 : 3 }}}
        width={'100%'}
      >
        <Stack
          spacing={{
            xs: 3,
            sm: vertical ? 3 : 1,
          }}
        >
          {/* 카테고리랑 가격 */}
          {/* <Stack direction="row" alignItems="center" sx={{my:{xs:-3, md: vertical ? -1.5 : 0}}}>
            {teacher?.teacherCategoryGroupInformation?.map((v :any,i :any)=>{
              return <Typography variant="overline" sx={{ color: 'primary.main' }}>{i === 0 ? "": " / "}{v.teacherCategoryInformation.teacherCategoryName }</Typography>
            })}
          </Stack> */}

          {/* 제목 */}
          <Stack spacing={1}>
            <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                component={RouterLink}
                to={`${paths.eLearning.teacher}?id=${teacher?.adminIdx}`}
                color="inherit"
              >
                <TextMaxLine variant="h6" line={1} onClick={() => {}}>
                  {teacher?.adminName}
                </TextMaxLine>
              </Link>
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ marginTop: '16px!important' }}>
          {teacher?.adminBirth === null && (
            <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
              나이 : 미입력
            </Typography>
          )}
          {teacher?.adminBirth !== null && (
            <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
              나이 : {teacher?.adminBirth}(
              {new Date().getFullYear() - new Date(teacher?.adminBirth).getFullYear()} 세)
            </Typography>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ marginTop: '4px!important' }}>
          <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
            성별 : {teacher?.adminGender === 'm' ? '남성' : '여성'}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ marginTop: '4px!important' }}>
          <Typography variant="body2" sx={{ mr: 0.5, color: '#637381' }}>
            {teacher?.adminComment}
          </Typography>
        </Stack>

        {/* <Stack alignItems="start" width={"100%"} minHeight={90} maxHeight={100} sx={{overflowY : "hidden"}}>
          <div dangerouslySetInnerHTML={{ __html: teacher?.teacherComment2 as string }}/>
        </Stack> */}
        {/* <Stack
          // spacing={1.5}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          divider={<Divider orientation="vertical" sx={{ height: 1 }} />}
        >
        </Stack> */}

        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        ></Stack>

        {/* <Divider
          sx={{
            borderStyle: 'dashed',
            py: 1,
            
          }}
        /> */}
        <Stack
          direction={'row'}
          sx={{
            gap: 2,
            display: 'flex',
            justifyContent: 'center',
            pl: { md: vertical ? 1 : 0 },
            pt: 1,
          }}
        >
          <Button
            variant="outlined"
            size="large"
            color="inherit"
            style={{ width: '100%' }}
            onClick={() => {
              navigate(`${paths.eLearning.teacher}?id=${teacher?.adminIdx}`);
            }}
          >
            <Typography fontWeight={600}>자세히 보기</Typography>
            {/* {usetimer} */}
          </Button>

          <Button
            size="large"
            variant="soft"
            color="success"
            style={{ width: '100%' }}
            onClick={() => {
              mutateCourses({
                adminIdx: teacher?.adminIdx,
                URL:
                  sessionStorage.getItem('workingEnv') === 'Build'
                    ? window.location.host
                    : (sessionStorage.getItem('workingEnv') as string),
                searchingKey: 'all',
              });
              setOpen(true);
            }}
          >
            <Typography fontWeight={600}>수업 구매하기</Typography>
            {/* {usetimer} */}
          </Button>
        </Stack>
      </Stack>

      <SelectProductModal
        Open={Open}
        setOpen={setOpen}
        data={CourseList}
        teacherIdx={teacher?.adminIdx}
        teacherName={teacher?.adminName}
        photo={teacher?.adminPhotoFilePath}
      />
    </Card>
  );
}
