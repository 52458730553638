import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
// types
import { ICourseProps } from 'src/types/course';
// components
import { useMutation } from '@tanstack/react-query';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsCart, BsCartFill } from 'react-icons/bs';
import { privateApi, publicApi } from 'src/react-query';
import { CreateWishlistDto } from 'src/generated';
import Timer from 'src/components/timer/Timer';
import ModalContainer, { ModalSize } from 'src/components/modal/ModalContainer';
import useResponsive from 'src/hooks/useResponsive';
import { ElearningCourseList } from '../../course/list';

// ----------------------------------------------------------------------

export default function SelectProductModal({ Open, setOpen, data ,teacherIdx ,teacherName ,photo}: any) {
  /* eslint-disable */
  const isMdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  return (
    <ModalContainer open={Open} handleClose={()=>{
        setOpen(false)
      }} 
      title='수업 선택'
      size={isMdUp ? ModalSize.LARGE : ModalSize.MSMALL}
      titlesize={"15px"}
      titleweight={600}
    >
      <Box height={data.length === 0 ? 500 : "85vh"} sx={{overflowY : "scroll"}}>
        {data.length !== 0 && <ElearningCourseList courses={data} type={"1"} from={"teacher"} teacherIdx={teacherIdx}  teacherName={teacherName} photo={photo}/>}
          {data.length === 0 && 
            <Box display="flex" flexDirection={"column"}>
              <Image
                alt="comingsoon"
                src="/assets/illustrations/illustration_comingsoon.svg"
                sx={{
                  my: 3,
                  mx: 'auto',
                  maxWidth: 320,
                }}
              />
                <span style={{ fontSize: 16, fontWeight: 400, margin: 'auto' ,color:"#9599a7"}}>
                  검색 조건에 맞는 수업이 없습니다.
                </span>
            </Box>
          }
      </Box>
    </ModalContainer>
  );
}